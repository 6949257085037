import React from "react";
import Team from "../components/Team"
import Card from "../components/Card"
import News from "../components/News"



const Home = () => {
    return (
        <>

            <Team />
            <Card />
            <News />
           
            
        </>
    )
}
export default Home;