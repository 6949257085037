import React from "react";
import User from "../assets/test/user.png"


const testimonials = // data/testimonials.json
  [
    {
      "id": "1",
      "tag": "AK_3D_Poly",
      "name": "Polypixerpioneer",
      "feedback": "“Mind-blown by Ultron's Text-to-3D & Img-to-3D character creator! As a student, diving into XR & gaming design just got a whole lot easier in Maya and Blender. Realistic characters in seconds? Count me in!”",
      "image": User
    },
    {
      "id": "2",
      "tag": "bharath_kumar89",
      "name": "Bharath",
      "feedback": "“Ultron is a game-changer for freelancers like me! Crafting lifelike characters in under 30 seconds? It's a dream come true. A good start and expecting a lot more efficiency and creative features!”",
      "image": User
    },
    {
      "id": "3",
      "tag": "EhsanOfficial03",
      "name": "H.M Ehsan",
      "feedback": "“Just tried #UltronAl of @metabrixlabs for the first time and WOW! As someone passionate about character creation, Generating fully rigged characters from simple prompts is not only fun but incredibly efficient. #ai #Creativity”",
      "image": User
    },
    {
      "id": "4",
      "tag": "AK_3D_Poly",
      "name": "Polypixerpioneer",
      "feedback": "“Mind-blown by Ultron's Text-to-3D & Img-to-3D character creator! As a student, diving into XR & gaming design just got a whole lot easier in Maya and Blender. Realistic characters in seconds? Count me in!”",
      "image": User
    },
    {
      "id": "5",
      "tag": "bharath_kumar89",
      "name": "Bharath",
      "feedback": "“Ultron is a game-changer for freelancers like me! Crafting lifelike characters in under 30 seconds? It's a dream come true. A good start and expecting a lot more efficiency and creative features!”",
      "image": User
    },
    // {
    //   "id": "6",
    //   "tag": "Polypixerpioneer",
    //   "name": "Fiona",
    //   "feedback": "“As a designer who has always worked in 2D spaces, Spline is the first 3D design software I was able to feel comfortable with and pick up with ease.”",
    //   "image": User
    // },
    // {
    //   "id": "7",
    //   "name": "George",
    //   "feedback": "“As a designer who has always worked in 2D spaces, Spline is the first 3D design software I was able to feel comfortable with and pick up with ease.”",
    //   "image": User
    // },
    // {
    //   "id": "8",
    //   "tag": "Polypixerpioneer",
    //   "name": "Hannah",
    //   "feedback": "“As a designer who has always worked in 2D spaces, Spline is the first 3D design software I was able to feel comfortable with and pick up with ease.”",
    //   "image": User
    // },
    // {
    //   "id": "9",
    //   "tag": "Polypixerpioneer",
    //   "name": "Ian",
    //   "feedback": "“As a designer who has always worked in 2D spaces, Spline is the first 3D design software I was able to feel comfortable with and pick up with ease.”",
    //   "image": User
    // },
    // {
    //   "id": "10",
    //   "tag": "Polypixerpioneer",
    //   "name": "Julia",
    //   "feedback": "“As a designer who has always worked in 2D spaces, Spline is the first 3D design software I was able to feel comfortable with and pick up with ease.”",
    //   "image": User
    // }
  ]


export default function Feedback() {
  return (
    <div className="p-4 mt-20">
      <div className="font-bold text-4xl text-center mb-16 la" style={{ letterSpacing: "0.05em" }}>
        Loved by <span className="text-lb">Artists</span> around the World
      </div>

      <div className="overflow-hidden flex items-center gap-[20px] feedback-main">
        <div className="feedback-loop md:gap-4 ">
          {testimonials.concat(testimonials).map((testimonial, index) => (
            <div
              key={index}
              className="p-4 my-4 flex flex-col bg-[#2B333D] min-h-[200px] md:min-h-[220px] h-fit md:max-w-[480px] min-w-[280px] md:w-[480px] rounded-xl"
            >
              <div className="flex items-center gap-4 p-2 min-h-fit ">
                <div className="relative bg-white h-[50px] w-[50px] rounded-full overflow-hidden">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    layout="fill"
                    objectfit="cover"
                  />
                </div>
                <div>
                  <h1 className="text">{testimonial.name}</h1>
                  <p className="sm-text">@{testimonial.tag}</p>
                </div>
              </div>
              <div className="h-full text-lg leading-6 flex items-center justify-center p-4">
                {testimonial.feedback}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
