import React from "react";
import { motion } from "framer-motion";

export default function Hero() {
  return (
    <div className="">
      <div className=" lg:flex lg:flex-row-reverse items-center lg:gap-20 justify-center xl:min-h-fit min-h-screen xl:mt-10 xl:py-16 mt-10 relative overflow-hidden">
        <motion.img
          src="/assets/images/side2.svg"
          className=" md:block hidden absolute bottom-3 right-0 translate-x-[15%] xl:h-[30%] lg:h-[20%] h-[15%]"
        />
        <div
          className=" border absolute top-[50%] right-0 translate-y-[50%] aspect-square h-[150px] -z-50 bg-pur"
          style={{
            filter: "blur(120px)",
          }}
        />
        <div className=" flex flex-col justify-center gap-4 p-4 w-fit lg:mx-0 mx-auto text-center relative">
          <h1 className="s-head font-bold la" style={{ letterSpacing: "0.05em" }}>
            <span className="text-lb">Emotionally</span> Expressive
          </h1>
          <p className="max-w-[480px] text mx-auto pl-1">
            Enhanced facial animation capabilities using 52 blend shapes for lifelike expressions and movements.
          </p>
        </div>
        <div className=" p-4 flex items-center justify-center lg:w-fit w-full sm:mx-0 mx-auto relative">
          <motion.img
            src="/assets/images/hamburger.svg"
            className=" absolute top-10 left-10"
          />
          <motion.div className=" lg:max-w-[600px] max-w-[400px] w-full ">
            <img
              className="w-full h-full object-cover rounded-2xl"
              src="https://media.ultronai.me/Images/website/er.gif" // Assuming this is the GIF URL
              alt="Emotionally Expressive"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}