import React, { useState, useEffect } from "react";
import Home from "./Home";
// import LoaderVideo from "../components/loaderVideo";

const Main = () => {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const videoPlayedTime = localStorage.getItem("videoPlayedTime");
  //   const currentTime = new Date().getTime();

  //   if (videoPlayedTime && currentTime - videoPlayedTime < 1 * 60 * 1000) {
  //     setLoading(false);
  //   } else {
  //     const timer = setTimeout(() => {
  //       setLoading(false);
  //     }, 5500);
  //     return () => clearTimeout(timer);
  //   }
  // }, []);

  return (
    <>
      <Home />
      {/* <div className="md:block hidden">
        <Home />
      </div>
      <div className="md:hidden block ">
        <Home />
      </div> */}
    </>
  );
};

export default Main;
