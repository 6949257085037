import React from "react";
import "./App.css";

// import Router
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import Component
import Header from "./components/Header";
import Footer from "./components/Footer";

// import Pages
import Main from "./Pages/Main";
import About from "./Pages/About";

function App() {
  return (
    <React.StrictMode>
      <Router>
        <Header />
          <Routes>
            <Route path='/' element={<Main/>} />
            <Route path="/about" element={<About/>} />
          </Routes>
        <Footer />
      </Router>
    </React.StrictMode>
  );
}

export default App;