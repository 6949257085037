import React from "react";
import { motion } from "framer-motion";

function Hero() {
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };
  return (
    <React.StrictMode>
      <div className="lg:flex lg:pt-8 pt-0 justify-between items-start min-h-full xl:px-16 lg:px-12 md:px-8 sm:px-4 p-0 max-w-[1400px] mx-auto">
        <div className="flex flex-col justify-center gap-4 lg:p-4 sm:px-12 px-4 py-2 lg:w-fit w-full lg:mx-0 mx-auto sm:min-h-auto min-h-[640px] relative overflow-hidden">
          <motion.div
            initial={{
              opacity: 1,
              scale: 0,
              x: "50%",
            }}
            animate={{
              opacity: 1,
              scale: 1,
              x: "50%",
            }}
            transition={{
              delay: 1.5,
              duration: 0.25,
            }}
            className="md:hidden flex border-2 absolute top-10 right-0 h-[75px] aspect-square rounded-full"
          />
          <motion.h1
            variants={container}
            initial="hidden"
            animate="visible"
            transition={{
              delay: 0.5,
              duration: 0.25,
            }}
            style={{
              lineHeight: 0.9,
            }}
            className="main-head font-bold la w-fit uppercase"
          >
            Super <br /> Charge
          </motion.h1>

          <motion.h5
            variants={container}
            initial="hidden"
            animate="visible"
            transition={{
              delay: 0.75,
              duration: 0.25,
            }}
            className="sub-head md:pl-[12px] pl-[8px] mb-2 la font-semibold max-w-[640px]"
            style={{
              letterSpacing: 1,
            }}
          >
            Your 3D Character creation with AI
          </motion.h5>

          <motion.div
            variants={container}
            initial="hidden"
            animate="visible"
            transition={{
              delay: 1,
              duration: 0.25,
            }}
            className="relative max-w-[520px] max-h-[319px]"
          >
            <motion.img src="/assets/images/back.svg" className=" " />
            <div className="absolute inset-0 h-full w-full flex flex-col justify-center md:gap-4 gap-1 *:h-fit">
              <div className="max-w-[80%] flex items-center px-[5%] py-2">
                <p
                  className="p-2 lg:leading-6 sm:leading-5 leading-4"
                  style={{
                    fontSize:
                      "clamp(0.8125rem, 0.6875rem + 0.4167vw, 1.0625rem)",
                  }}
                >
                  Generate ready to use 3D avatars within minutes for your games,
                  XR and marketing needs
                </p>
              </div>
              <div className="text-center flex flex-col items-center justify-start *:max-h-[50%] *:h-fit gap-2 py-2 *:max-w-[90%] *:w-full">
                <a
                  href={"https://ultronai.me"}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <p
                    className=" lg:py-5 py-3 cursor-pointer font-bold rounded-xl uppercase text  "
                    // className=" lg:py-5 py-3 cursor-pointer bg-[#8e8e8e63] font-bold rounded-xl uppercase text inner-shadow-mbrix"
                    style={{
                      fontSize: "clamp(0.875rem, 0.8125rem + 0.2083vw, 2rem)",
                      background: "linear-gradient(to bottom right, var(--tw-gradient-from), var(--tw-gradient-to))",
                      '--tw-gradient-from': '#7fc2d7', // Replace with your actual color value
                      '--tw-gradient-to': '#c8c260'
                    }}
                  >
                    TRY ULTRON AI FOR FREE
                  </p>
                </a>
                <a
                  href={"https://discord.com/invite/FXzz3mFSWY"}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <p
                    className=" border-4 py-4 cursor-pointer rounded-xl text"
                    style={{
                      fontSize: "clamp(0.875rem, 0.8125rem + 0.2083vw, 1.8rem)",
                    }}
                  >
                    Join Discord Community
                  </p>
                </a>
              </div>
            </div>
          </motion.div>
        </div>
        <div className="relative p-4 flex items-center justify-center lg:w-fit w-full sm:mx-0 mx-auto">
          <motion.img
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.2,
              delay: 0.5,
            }}
            src="/assets/images/hero_page.webp"
            className=" lg:max-w-[500px] max-w-[370px] max-h-screen w-full"
          />
        </div>
      </div>
    </React.StrictMode>
  );
}

export default Hero;
