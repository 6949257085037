export const Discord = ({ size, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 48 48"
  >
    <path
      fill={fill}
      d="M39.248,10.177c-2.804-1.287-5.812-2.235-8.956-2.778c-0.057-0.01-0.114,0.016-0.144,0.068	c-0.387,0.688-0.815,1.585-1.115,2.291c-3.382-0.506-6.747-0.506-10.059,0c-0.3-0.721-0.744-1.603-1.133-2.291	c-0.03-0.051-0.087-0.077-0.144-0.068c-3.143,0.541-6.15,1.489-8.956,2.778c-0.024,0.01-0.045,0.028-0.059,0.051	c-5.704,8.522-7.267,16.835-6.5,25.044c0.003,0.04,0.026,0.079,0.057,0.103c3.763,2.764,7.409,4.442,10.987,5.554	c0.057,0.017,0.118-0.003,0.154-0.051c0.846-1.156,1.601-2.374,2.248-3.656c0.038-0.075,0.002-0.164-0.076-0.194	c-1.197-0.454-2.336-1.007-3.432-1.636c-0.087-0.051-0.094-0.175-0.014-0.234c0.231-0.173,0.461-0.353,0.682-0.534	c0.04-0.033,0.095-0.04,0.142-0.019c7.201,3.288,14.997,3.288,22.113,0c0.047-0.023,0.102-0.016,0.144,0.017	c0.22,0.182,0.451,0.363,0.683,0.536c0.08,0.059,0.075,0.183-0.012,0.234c-1.096,0.641-2.236,1.182-3.434,1.634	c-0.078,0.03-0.113,0.12-0.075,0.196c0.661,1.28,1.415,2.498,2.246,3.654c0.035,0.049,0.097,0.07,0.154,0.052	c3.595-1.112,7.241-2.79,11.004-5.554c0.033-0.024,0.054-0.061,0.057-0.101c0.917-9.491-1.537-17.735-6.505-25.044	C39.293,10.205,39.272,10.187,39.248,10.177z M16.703,30.273c-2.168,0-3.954-1.99-3.954-4.435s1.752-4.435,3.954-4.435	c2.22,0,3.989,2.008,3.954,4.435C20.658,28.282,18.906,30.273,16.703,30.273z M31.324,30.273c-2.168,0-3.954-1.99-3.954-4.435	s1.752-4.435,3.954-4.435c2.22,0,3.989,2.008,3.954,4.435C35.278,28.282,33.544,30.273,31.324,30.273z"
    />
  </svg>
);

export const Instagram = ({ size, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 50 50"
    fill={fill}
  >
    <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z" />
  </svg>
);

export const Linkedin = ({ size, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_802_818)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.45898 4.13941C1.45898 3.42851 1.74139 2.74674 2.24406 2.24406C2.74674 1.74139 3.42851 1.45899 4.1394 1.45899H30.859C31.2113 1.45841 31.5602 1.52733 31.8859 1.66179C32.2115 1.79626 32.5074 1.99363 32.7566 2.2426C33.0059 2.49158 33.2036 2.78727 33.3384 3.11275C33.4732 3.43823 33.5425 3.78711 33.5423 4.13941V30.859C33.5427 31.2114 33.4736 31.5603 33.339 31.886C33.2044 32.2116 33.0069 32.5075 32.7578 32.7567C32.5087 33.006 32.2129 33.2036 31.8873 33.3384C31.5618 33.4732 31.2128 33.5425 30.8604 33.5423H4.1394C3.78728 33.5423 3.43861 33.4729 3.11331 33.3382C2.78802 33.2034 2.49246 33.0058 2.24354 32.7567C1.99463 32.5077 1.79722 32.212 1.6626 31.8866C1.52798 31.5613 1.45879 31.2126 1.45898 30.8604V4.13941ZM14.1582 13.6915H18.5025V15.8732C19.1296 14.619 20.7338 13.4902 23.1444 13.4902C27.7659 13.4902 28.8611 15.9884 28.8611 20.5719V29.0623H24.1842V21.6161C24.1842 19.0057 23.5571 17.5327 21.9646 17.5327C19.7552 17.5327 18.8365 19.1209 18.8365 21.6161V29.0623H14.1582V13.6915ZM6.13732 28.8625H10.8157V13.4902H6.13732V28.8611V28.8625ZM11.485 8.47649C11.4938 8.87705 11.4226 9.27534 11.2754 9.64799C11.1282 10.0206 10.908 10.3601 10.6279 10.6465C10.3477 10.9329 10.0131 11.1605 9.64382 11.3159C9.2745 11.4712 8.87788 11.5513 8.47721 11.5513C8.07655 11.5513 7.67993 11.4712 7.31061 11.3159C6.9413 11.1605 6.60674 10.9329 6.32656 10.6465C6.04639 10.3601 5.82625 10.0206 5.67905 9.64799C5.53186 9.27534 5.46058 8.87705 5.4694 8.47649C5.48672 7.69023 5.81121 6.94202 6.3734 6.39207C6.93559 5.84213 7.69077 5.53418 8.47721 5.53418C9.26366 5.53418 10.0188 5.84213 10.581 6.39207C11.1432 6.94202 11.4677 7.69023 11.485 8.47649Z"
        fill={fill}
      />
    </g>
    <defs>
      <filter
        id="filter0_i_802_818"
        x={0}
        y={0}
        width={35}
        height={39}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_802_818"
        />
      </filter>
    </defs>
  </svg>
);

export const XT = ({ size, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 50 50"
    fill={fill}
  >
    <path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z" />
  </svg>
);

export const Email = ({ size, fill }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 128 128"
    fill={fill}
  >
    <path d="M123.25 24.192c0-.018 0-.034-.005-.052s-.007-.063-.009-.094a1.734 1.734 0 0 0-.083-.408c-.006-.018 0-.037-.011-.055s-.01-.015-.013-.023a1.734 1.734 0 0 0-.227-.407c-.021-.028-.043-.053-.066-.08a1.755 1.755 0 0 0-.31-.294c-.012-.009-.022-.02-.034-.028a1.744 1.744 0 0 0-.414-.2c-.034-.012-.068-.022-.1-.032a1.733 1.733 0 0 0-.474-.073H6.5a1.733 1.733 0 0 0-.474.073c-.035.01-.068.02-.1.032a1.744 1.744 0 0 0-.414.2c-.012.008-.022.019-.034.028a1.755 1.755 0 0 0-.31.294c-.022.027-.045.052-.066.08a1.734 1.734 0 0 0-.227.407c0 .008-.01.015-.013.023s-.005.037-.011.055a1.734 1.734 0 0 0-.083.408c0 .032-.009.063-.009.094s-.005.034-.005.052v79.615c0 .023.006.045.007.068a1.737 1.737 0 0 0 .019.188c.008.051.015.1.027.152a1.74 1.74 0 0 0 .056.179c.017.047.033.094.054.139a1.729 1.729 0 0 0 .093.172c.024.04.048.081.075.119a1.743 1.743 0 0 0 .125.152c.033.036.066.072.1.106.021.019.037.042.059.061s.036.017.052.03a1.736 1.736 0 0 0 .452.263c.035.014.071.022.107.033a1.732 1.732 0 0 0 .488.085c.012 0 .023.006.035.006H121.501c.012 0 .023-.006.034-.006a1.732 1.732 0 0 0 .489-.085c.035-.011.07-.019.1-.033a1.736 1.736 0 0 0 .453-.263c.016-.013.036-.017.052-.03s.038-.042.059-.061c.036-.034.069-.069.1-.106a1.743 1.743 0 0 0 .125-.152c.027-.038.051-.078.075-.119a1.729 1.729 0 0 0 .093-.172c.021-.045.037-.092.054-.139a1.74 1.74 0 0 0 .056-.179c.012-.05.019-.1.027-.152a1.737 1.737 0 0 0 .019-.188c0-.023.007-.045.007-.068zM45.8 60.316l17.058 14.677a1.751 1.751 0 0 0 2.283 0L82.2 60.316l35.512 41.741H10.289zM8.25 99.052V28.007l34.9 30.026zm76.6-41.019 34.9-30.026v71.045zm31.931-32.091L81.276 56.493c-.006.005-.014.008-.02.014l-.019.02L64 71.358 46.763 56.527l-.019-.02-.02-.014-35.507-30.551z" />
  </svg>
);

export const Web = ({size , fill}) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 128 128"
    fill={fill}
  >
    <path d="M111.848 98.888a59.075 59.075 0 0 0 0-69.776 1.429 1.429 0 0 0-.366-.514 59.193 59.193 0 0 0-94.964 0 1.727 1.727 0 0 0-.164.173 1.747 1.747 0 0 0-.2.34 59.075 59.075 0 0 0 0 69.776 1.425 1.425 0 0 0 .365.513 59.192 59.192 0 0 0 94.979-.02 1.7 1.7 0 0 0 .149-.153 1.747 1.747 0 0 0 .201-.339zM64 83.11a95.037 95.037 0 0 0-28.182 4.1 93.5 93.5 0 0 1-2.8-21.459h61.966a93.5 93.5 0 0 1-2.8 21.459A95.037 95.037 0 0 0 64 83.11zm27.236 7.517C85.972 107.963 75.738 119.75 64 119.75s-21.972-11.787-27.236-29.123A91.85 91.85 0 0 1 64 86.61a91.85 91.85 0 0 1 27.236 4.017zM8.294 65.75h21.224a95.362 95.362 0 0 0 3.026 22.563 60.5 60.5 0 0 0-14.376 7.375A55.405 55.405 0 0 1 8.294 65.75zm9.873-33.437a60.5 60.5 0 0 0 14.376 7.375 95.362 95.362 0 0 0-3.025 22.562H8.294a55.405 55.405 0 0 1 9.874-29.937zM64 44.89a95.037 95.037 0 0 0 28.182-4.1 93.5 93.5 0 0 1 2.8 21.459H33.016a93.5 93.5 0 0 1 2.8-21.459A95.037 95.037 0 0 0 64 44.89zm-27.236-7.517C42.028 20.037 52.262 8.25 64 8.25s21.972 11.787 27.236 29.123A91.85 91.85 0 0 1 64 41.39a91.85 91.85 0 0 1-27.236-4.017zm82.942 24.877H98.482a95.362 95.362 0 0 0-3.026-22.563 60.5 60.5 0 0 0 14.376-7.375 55.405 55.405 0 0 1 9.874 29.938zm-21.223 3.5h21.223a55.405 55.405 0 0 1-9.873 29.938 60.5 60.5 0 0 0-14.376-7.375 95.362 95.362 0 0 0 3.025-22.563zm9.262-36.25a56.971 56.971 0 0 1-13.26 6.774C90.931 24.8 85.244 15.569 78.339 10.128A55.874 55.874 0 0 1 107.745 29.5zM49.661 10.128C42.756 15.569 37.069 24.8 33.515 36.275a56.971 56.971 0 0 1-13.26-6.775 55.874 55.874 0 0 1 29.406-19.372zM20.255 98.5a56.971 56.971 0 0 1 13.26-6.774c3.554 11.474 9.24 20.706 16.146 26.147A55.874 55.874 0 0 1 20.255 98.5zm58.084 19.373c6.906-5.44 12.592-14.673 16.146-26.147a56.971 56.971 0 0 1 13.26 6.774 55.874 55.874 0 0 1-29.406 19.372z" />
  </svg>
);
