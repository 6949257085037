"use client";
import React, { useRef, useEffect, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const video = [
  { id: 1, text: "Text-to-3D", link: "https://dmh29xnc6sm1d.cloudfront.net/Images/website/pr.gif ", title: "Personalized Games", description: "Create personalized avatars in real time from an image or text prompt, tailoring them to look exactly how you envision for an enhanced gaming experience." },
  { id: 2, text: "Img-to-3D", link: "https://dmh29xnc6sm1d.cloudfront.net/Images/website/ai.gif", title: "Intelligent NPCs", description: "Enable NPC companions with context based intelligence such that they can carry out speech based conversations and build strategies in the game." },
  { id: 3, text: "Converse3D", link: "https://dmh29xnc6sm1d.cloudfront.net/Images/website/mcc.gif", title: "Mass Character Creator", description: "Effortlessly generate thousands of characters for crowds and NPCs, enhancing both variety and efficiency in your game development process." },
];

export default function Features() {
  const container = useRef(null);
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["0 0", "1 1"],
  });

  const y = useTransform(scrollYProgress, [0, 1], ["15%", "-70%"]);
  const countY = useTransform(scrollYProgress, [0.1, 1], ["0.5%", "-66%"]);

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange((latest) => {
      const index = Math.floor(latest * 3);
      setActiveVideoIndex(Math.min(index, 2));
    });

    return () => unsubscribe();
  }, [scrollYProgress]);

  return (
    <div
      ref={container}
      id="features"
      className="h-[300vh] relative md:mt-0 my-12 max-w-[1400px]"
    >
      <h1 className="t-head text-center la font-bold mt-10" style={{ letterSpacing: "0.05em" }}>
        Generate<span className="text-lb"> ready to use</span> 3D characters
      </h1>
      <div className="h-screen sticky top-0 xl:p-16 lg:p-12 md:p-8 sm:p-4 p-4 md:flex items-center justify-center lg:min-h-screen w-screen ">
        <div className="flex flex-col gap-4 ">

          <div className="md:hidden hidden items-center justify-between border"></div>

          <div className="main-wrapper flex flex-col sm:w-[100vw] flex-wrap md:h-[450px] lg:w-[60vw] lg:h-screen overflow-hidden">
            <motion.div
              style={{
                y,
              }}
              className="w-full flex flex-col items-center gap-6 justify-center"
            >
              {video.map((_, i) => (
                <Slide
                  text={video[i].text}
                  key={i}
                  i={i}
                  progress={scrollYProgress}
                  isActive={i === activeVideoIndex}
                />
              ))}
            </motion.div>
          </div>
        </div>

        <div className="p-4 hidden md:flex items-center justify-center relative">
          <motion.img
            src="/assets/images/hamburger.svg"
            className="absolute top-20 right-20"
          />
          <motion.img
            src="/assets/images/Pointing_character_without bg.png"
            className="lg:max-w-[600px] max-w-[400px] w-full"
          />
        </div>
      </div>
    </div>
  );
}

const Slide = ({ text, i, progress, isActive }) => {
  const start = (i - 1) / 3;
  const end = i / 3 + start / 2;
  const scale = useTransform(progress, [start, end], [0.7, 1]);
  const opacity = useTransform(progress, [start, end], [0.2, 1]);
  const textOpacity = useTransform(progress, [start, end], [0, 1]);

  return (
    <motion.div
      style={{
        scale,
        opacity,
        marginBottom: "2rem" // Added margin bottom for spacing
      }}
      className="w-full h-[250px] min-h-[30vh] flex flex-col md:flex-row justify-center items-center gap-2 "
    >
      <div className="h-full flex justify-center items-center max-w-[75%] w-full rounded-2xl bg-[#8e8e8e]">
        <img
          className="w-full h-full object-cover rounded-2xl"
          src={video[i].link} // Assuming the link is to a GIF image
          alt={video[i].title}
        />
      </div>
      <div className="w-[95%] items-center justify-center py-4 pl-8 flex flex-col flex-wrap gap-2">
        <h2 className="text-2xl text-[#00FFFF] font-[Lalezar]  w-4/5">{video[i].title}</h2>
        <p className="text-sm font-[inter] w-4/5">{video[i].description}</p>
      </div>
    </motion.div>
  );
};