import React from "react";
import { motion } from "framer-motion";

export default function Hero() {
  return (
    <div className="">

      <div className=" lg:flex items-center lg:gap-20 justify-center xl:min-h-fit min-h-full xl:mt-10 xl:py-16  relative overflow-hidden">
        <div

          className=" border absolute top-[50%] left-0 translate-y-[50%] aspect-square h-[150px] -z-50 bg-pur"
          style={{
            filter: "blur(120px)",
          }}
        />
        <motion.img
          src="/assets/images/side.svg"
          className=" md:block hidden absolute bottom-3 left-0 -translate-x-[15%] xl:h-[15%] lg:h-[10%] h-[8.5%]"
        />

        <div className=" flex flex-col justify-center gap-4 p-4 w-fit lg:mx-0 mx-auto text-center relative">
          <motion.img
            src="/assets/images/hamburger.svg"
            className=" absolute -top-20 left-0"
          />
          <h1 className="p-head font-bold la" style={{ letterSpacing: "0.05em" }}>
            <span className=" text-lb">Motion</span> Ready
          </h1>
          <p className="max-w-[480px] text mx-auto pl-1">
          Fully rigged character compatible with Mixamo, Unity, and Unreal Engine for seamless integration and animation.
          </p>
        </div>
        <div className=" p-4 flex items-center justify-center lg:w-fit w-full sm:mx-0 mx-auto relative">
          <motion.img
            src="/assets/images/hamburger.svg"
            className=" absolute bottom-20 right-20"
          />
          <motion.img
            src="/assets/images/hero2.png"
            className=" lg:max-w-[600px] max-w-[400px] w-full "
          />
        </div>
      </div>
    </div>
  );
}
