import React from "react";
import firstImage from '../assets/images/workingimage1.png';
import secondImage from '../assets/images/workingimage2.png';
import galary from '../assets/images/galaryIcon.png';
import textIcon from '../assets/images/textIcon.png';
import settingIcon from '../assets/images/settingIcon.png';
import finalImage from '../assets/images/workingimagefinal.png';
import communityIcon from '../assets/images/communityIcon.png';
import customizerIcon from '../assets/images/customizerIcon.png';
import workingPath1 from '../assets/images/workingPath1.svg';
import workingPath2 from '../assets/images/workingPath2.svg';
import creationPath from '../assets/images/creation-path.svg'
import step_2_1 from "../assets/images/step_2 (1).svg";
import step_2_2 from "../assets/images/step_2 (2).svg";
import step_2_3 from "../assets/images/step_2 (3).svg";
import step_2_ecliops from "../assets/images/step_2_ecliops.svg";
import step_3_1 from "../assets/images/step_3 (1).svg";
import step_3_2 from "../assets/images/step_3 (2).svg";
import step_3_3 from "../assets/images/step_3 (3).svg";
import grid from '../assets/images/grid.svg';
import browserIcon from '../assets/images/browser.png';
import game_engine from '../assets/images/game_engine.png';
import intelligence from '../assets/images/Intelligence.png';
import llm from '../assets/images/llm.png';
import speech from '../assets/images/speech.png';
import virtual from '../assets/images/virtual.png';




function Features ({ url, text, text2, style ,isMobile }){
  return (
    isMobile    ?

      <div className="feature-wrapper flex-col flex lg:flex-row gap-2 absolute items-center " style={style}>
        <img src={url} className="w-6 h-6" alt="" />
        <div className="flex flex-col justify-center">
            <p className="text-sm text-inline">{text}</p>
            <p className="text-sm text-center">{text2}</p>
        </div>
    </div>
    :



      <div className="feature-wrapper flex-col flex lg:flex-row gap-2 absolute items-center " style={style}>
        <img src={url} className="w-8 h-8" alt="" />
        <div className="flex flex-col justify-center">
            <p className="text-lg">{text}</p>
            <p className="text-lg">{text2}</p>
        </div>
    </div>

    )
};

function Working() {
    const isMobile = window.innerWidth < 768;
    return (
        isMobile ?
        <div className="working-wrapper w-screen  flex flex-col lg:pt-8 pt-0 justify-start lg:justify-between items-start  my-6 xl:px-16 lg:px-12 md:px-8 sm:px-4 p-0 max-w-[1400px] mx-auto">
            <div className="heading-container  flex items-center gap-4 ml-10">
                <div className="outer-circle w-6 h-6 bg-[#03979B] rounded-full flex justify-center items-center">
                    <div className="circle w-4 h-4 bg-[#00FFFF] rounded-full "></div>
                </div>
                <h1 className="text-2xl text-left font-[lalezar] text-[#00FFFF]">How It Works</h1>
            </div>
            <div className="step1 flex flex-col justify-normal lg:flex-row relative w-full lg:items-end gap-6 mb-8 ">


                <div className="container-heading flex flex-col text-3xl md:text-5xl font-bold font-[lalezar] lg:gap-2 ml-20 mt-8" style={{ letterSpacing: "0.1em" }}>
                    <h1>1.</h1>
                    <h1>CHARACTER  <br /> CREATION</h1>
                </div>
                <div className="image-section relative pt-[-50px] ">
                    <img src={firstImage} className=" scale-[1] mx-auto " alt="" />
                    <img src={creationPath} alt="" className="rotate-90 -z-10 absolute left-[-5%]  top-[117%] lg:top-[32%]  scale-[1] lg:scale-[1.75] lg:left-[135%]  md:block "
                        style={{
                            width: '100%',
                            height: '100%',
                            
                        }} 
                        />
                    {/* <img src={grid} alt="" className="absolute top-[20%] left-[-150%] z-[-10] "
                        style={{
                            width: '100%',
                            height: '100%',
                            scale: "2"
                        }} />
                    <img src={grid} alt="" className="absolute top-[220%] left-[-150%] z-[-10] "
                        style={{
                            width: '100%',

                            height: '100%',
                            scale: "2"
                        }} /> */}
                </div>

                <Features isMobile={isMobile} url={galary}   text="Image-to-3D" style={{ left: '0%', bottom: '-72%' }} />
                <Features isMobile={isMobile} url={textIcon} text="Text-to-3D" style={{ left: '18%', bottom: '-98%' }} />
                <Features isMobile={isMobile} url={settingIcon} text="Dashboard" style={{ left: '35%', bottom: '-75%' }} />
                <Features isMobile={isMobile} url={communityIcon} text="Community" text2={"Showcase"} style={{ left: '53%', bottom: '-100%' }} />
                <Features isMobile={isMobile} url={customizerIcon} text="Customizer" style={{ left: '70%', bottom: '-69%' }} />
            </div>

            <div className="step2 flex flex-col relative w-full gap-2 lg:items-center mt-[400px]">


                <div className="container-heading flex flex-col text-3xl lg:text-5xl font-bold font-[lalezar]  ml-20" style={{ letterSpacing: "0.1em" }}>
                    <h1>2.</h1>
                    <h1>CHARACTER <br /> CONVERSATION</h1>
                </div>

                <img src={workingPath1} className="hidden lg:block absolute top-[-60%] left-[10%]" alt="" />
                <img src={workingPath2} className="hidden lg:block absolute top-[65%] right-[9%]" alt="" />
                <div className="image-section relative">
                    <img src={secondImage} alt="" className="scale-[0.8] lg:scale-[1] ml-[-10px]" />
                    <img src={step_2_1} alt="" className="absolute top-[-25%] lg:top-[-24%] left-[3%] md:left-[-5%] lg:left-[62%] scale-[0.35] lg:scale-[1.2]  md:block"
                        style={{
                            width: '110%',
                            height: '110%',
                            
                        }} />
                    <img src={step_2_3} alt="" className="absolute top-[15%] md:top-[20%] lg:top-[25%]  left-[5%] md:left-[-10%] lg:left-[50%] scale-[0.4] lg:scale-[1] md:block"
                        style={{
                            width: '110%',
                            height: '110%',
                            
                        }} />
                    <img src={step_2_1} alt="" className="absolute top-[0%] lg:top-[10%] left-[10%] md:left-[-10%] lg:left-[-80%] scale-[0.3] lg:scale-[1]  md:block"
                        style={{
                            width: '110%',
                            height: '110%',
                           
                        }} />
                    <img src={step_2_ecliops} alt="" className="absolute top-[-11%] left-[-5%]  "
                        style={{
                            width: '80%',
                            height: '80%',
                            scale: "0.3"
                        }} />

                    <img src={grid} alt="" className="absolute top-[10%] right-[-150%] z-[-10] hidden md:block"
                        style={{
                            width: '100%',

                            height: '100%',
                            scale: "2"
                        }} />
                    <img src={grid} alt="" className="absolute top-[210%] right-[-150%] z-[-10] hidden md:block"
                        style={{
                            width: '100%',

                            height: '100%',
                            scale: "2"
                        }} />

                </div>
                <Features isMobile={isMobile} url={intelligence} text="Intelligence Infusion" style={{ right: '4%', top: '47%' }} />
                <Features isMobile={isMobile} url={llm} text="LLM Integration" style={{ right: '5%', top: '63%' }} />
                <Features isMobile={isMobile} url={speech} text="Speech and Text " text2={"based conversation"} style={{ right: '2%', top: '82%' }} />
            </div>

            <div className="step3 flex flex-col relative gap-8 justify-cente sm:items-center lg:mt-[80px] lg:mb-[100px] mt-10 mb-[350px]">


                <div className="container-heading flex flex-col text-3xl lg:text-5xl font-bold font-[lalezar] gap-2 ml-10" style={{ letterSpacing: "0.1em" }}>
                    <h1>3.</h1>
                    <h1>CHARACTER <br /> SIMULATION</h1>
                </div>
                <div className="image-section relative">
                    <img src={finalImage} alt="" className="lg:scale-[1] scale-[1] ml-10 " />
                    <img src={step_3_1} alt="" className=" rotate-90 absolute lg:rotate-0 top-[90%] -z-10 lg:top-[1%] scale-[0.4] lg:scale-[1.2] left-[30%] lg:left-[110%]  "
                        style={{
                            width: '100%',
                            height: '100%',
                            scale: "2"
                            
                        }} />
                    <img src={step_3_2} alt="" className="absolute rotate-90 lg:rotate-0 top-[90%] -z-10 lg:top-[21%] left-[10%] lg:left-[115%] scale-[0.35] lg:scale-[1] "
                        style={{
                            width: '100%',
                            height: '100%',
                            scale: "2.5"
                        }} />
                    <img src={step_3_3} alt="" className="absolute rotate-90 lg:rotate-0 top-[90%] -z-10 lg:top-[42%] scale-[0.4] lg:scale-[1] left-[1%] lg:left-[100%]"
                        style={{
                            width: '80%',
                            height: '80%',
                            scale: "2"
                            
                        }} />
                </div>
                <Features isMobile={isMobile} url={virtual} text="Virtual World " text2="Simulation" style={{left: '11%', top: '144%' }} /> 
            <Features isMobile={isMobile} url={browserIcon} text="Browser based" text2=" games" style={{left: '40%', top: '157%' }} />
                <Features isMobile={isMobile} url={game_engine} text="Game engine" text2="integration" style={{left: '75%', top: '155%' }} /> 
            </div>
        </div>
        :













        <div className="working-wrapper w-screen  flex flex-col lg:pt-8 pt-0 justify-start lg:justify-between items-start  my-6 xl:px-16 lg:px-12 md:px-8 sm:px-4 p-0 max-w-[1400px] mx-auto">
        <div className="heading-container  flex items-center gap-4">
            <div className="outer-circle w-6 h-6 bg-[#03979B] rounded-full flex justify-center items-center">
                <div className="circle w-4 h-4 bg-[#00FFFF] rounded-full "></div>
            </div>
            <h1 className="text-2xl text-left font-[lalezar] text-[#00FFFF]">How It Works</h1>
        </div>
        <div className="step1 flex flex-col justify-normal lg:flex-row relative w-full lg:items-end gap-6 mb-8 ">


            <div className="container-heading flex flex-col text-3xl md:text-5xl font-bold font-[lalezar] lg:gap-2 ml-10 " style={{ letterSpacing: "0.1em" }}>
                <h1>1.</h1>
                <h1>CHARACTER  <br /> CREATION</h1>
            </div>
            <div className="image-section relative pt-[-50px]">
                <img src={firstImage} className="mx-auto scale-[0.6] sm:scale-100 lg:scale-[1]" alt="" />
                <img src={creationPath} alt="" className="rotate-90 lg:rotate-0 absolute left-0 top-[72%] lg:top-[32%]  scale-[0.60] lg:scale-[1.75] lg:left-[135%]  md:block "
                    style={{
                        width: '100%',
                        height: '100%',
                        
                    }} />
                <img src={grid} alt="" className="absolute top-[20%] left-[-150%] z-[-10] hidden md:block"
                    style={{
                        width: '100%',
                        height: '100%',
                        scale: "2"
                    }} />
                <img src={grid} alt="" className="absolute top-[220%] left-[-150%] z-[-10] hidden md:block"
                    style={{
                        width: '100%',

                        height: '100%',
                        scale: "2"
                    }} />
            </div>

            <Features url={galary} text="Image-to-3D" style={{ left: '68%', top: '19%' }} />
            <Features url={textIcon} text="Text-to-3D" style={{ left: '79%', top: '44%' }} />
            <Features url={settingIcon} text="Dashboard" style={{ left: '72%', top: '73%' }} />
            <Features url={communityIcon} text="Community" text2={"Showcase"} style={{ left: '80%', top: '97%' }} />
            <Features url={customizerIcon} text="Customizer" style={{ left: '71%', top: '128%' }} />
        </div>

        <div className="step2 flex flex-col relative w-full gap-2 lg:items-center lg:mt-[250px]">


            <div className="container-heading flex flex-col text-3xl lg:text-5xl font-bold font-[lalezar] gap-2 ml-10" style={{ letterSpacing: "0.1em" }}>
                <h1>2.</h1>
                <h1>CHARACTER <br /> CONVERSATION</h1>
            </div>

            <img src={workingPath1} className="hidden lg:block absolute top-[-60%] left-[10%]" alt="" />
            <img src={workingPath2} className="hidden lg:block absolute top-[65%] right-[9%]" alt="" />
            <div className="image-section relative">
                <img src={secondImage} alt="" className="scale-[0.5] lg:scale-[1]" />
                <img src={step_2_1} alt="" className="absolute top-[-20%] lg:top-[-24%] left-[5%] md:left-[-5%] lg:left-[62%] scale-[0.35] lg:scale-[1.2]  md:block"
                    style={{
                        width: '100%',
                        height: '100%',
                        
                    }} />
                <img src={step_2_3} alt="" className="absolute top-[11%] md:top-[20%] lg:top-[25%]  left-[3%] md:left-[-10%] lg:left-[50%] scale-[0.4] lg:scale-[1] md:block"
                    style={{
                        width: '100%',
                        height: '100%',
                        
                    }} />
                <img src={step_2_1} alt="" className="absolute top-[5%] lg:top-[10%] left-[5%] md:left-[-10%] lg:left-[-80%] scale-[0.3] lg:scale-[1]  md:block"
                    style={{
                        width: '100%',
                        height: '100%',
                       
                    }} />
                <img src={step_2_ecliops} alt="" className="absolute top-[-25%] left-[1px]  hidden md:block"
                    style={{
                        width: '100%',
                        height: '100%',
                        scale: "0.3"
                    }} />

                <img src={grid} alt="" className="absolute top-[10%] right-[-150%] z-[-10] hidden md:block"
                    style={{
                        width: '100%',

                        height: '100%',
                        scale: "2"
                    }} />
                <img src={grid} alt="" className="absolute top-[210%] right-[-150%] z-[-10] hidden md:block"
                    style={{
                        width: '100%',

                        height: '100%',
                        scale: "2"
                    }} />

            </div>
            <Features url={intelligence} text="Intelligence Infusion" style={{ right: '4%', top: '48%' }} />
            <Features url={llm} text="LLM Integration" style={{ left: '5%', top: '70%' }} />
            <Features url={speech} text="Speech and Text " text2={"based conversation"} style={{ right: '10%', top: '87%' }} />
        </div>

        <div className="step3 flex flex-col relative gap-8 justify-cente sm:items-center lg:mt-[80px] lg:mb-[100px]">


            <div className="container-heading flex flex-col text-3xl lg:text-5xl font-bold font-[lalezar] gap-2 ml-10" style={{ letterSpacing: "0.1em" }}>
                <h1>3.</h1>
                <h1>CHARACTER <br /> SIMULATION</h1>
            </div>
            <div className="image-section relative">
                <img src={finalImage} alt="" className="lg:scale-[1] scale-[0.5] " />
                <img src={step_3_1} alt="" className=" rotate-90 absolute lg:rotate-0 top-[42%] lg:top-[1%] scale-[0.4] lg:scale-[1.2] left-[10%] lg:left-[110%]  "
                    style={{
                        width: '100%',
                        height: '100%',
                        
                    }} />
                <img src={step_3_2} alt="" className="absolute rotate-90 lg:rotate-0 top-[45%]  lg:top-[21%] left-[0%] lg:left-[115%] scale-[0.35] lg:scale-[1] "
                    style={{
                        width: '100%',
                        height: '100%',
                        scale: "1.3"
                    }} />
                <img src={step_3_3} alt="" className="absolute rotate-90 lg:rotate-0 top-[42%] lg:top-[42%] scale-[0.4] lg:scale-[1] left-[-15%] lg:left-[100%]"
                    style={{
                        width: '100%',
                        height: '100%',
                        
                    }} />
            </div>
            <Features url={virtual} text="Virtual World " text2="Simulation" style={{right: '-145%', top: '59%' }} />
            <Features url={browserIcon} text="Browser based games" style={{right: '-180%', top: '82%' }} />
            <Features url={game_engine} text="Game engine integration" style={{right: '-160%', top: '105%' }} />
        </div>
    </div>
    );
}

export default Working;
