import React from "react";
import "./App.css";

// import Router
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import Component
import Header from "./components/Header";
import Footer from "./components/Footer";

// import Pages
import Main from "./Pages/Main";
import About from "./Pages/About";

function App() {
window.addEventListener('unhandledrejection', function(event) {
  console.error('Unhandled promise rejection:', event.reason);
});

window.addEventListener('error', function(event) {
  console.error('Error occurred:', event.message, 'at', event.filename, 'line', event.lineno, 'column', event.colno);
});
window.addEventListener('warning', function(event) {
  console.warning('Error occurred:', event.message, 'at', event.filename, 'line', event.lineno, 'column', event.colno);
});
  return (
    <React.StrictMode>
      <Router>
        <Header />
          <Routes>
            <Route path='/' element={<Main/>} />
            <Route path="/about" element={<About/>} />
          </Routes>
        <Footer />
      </Router>
    </React.StrictMode>
  );
}

export default App;